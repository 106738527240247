.ui.segment.error-pageui-code{    
  background:transparent; 
  border-radius: 6px;
  box-shadow:none;
  border: 1px solid #FFFFFF;
}

.contentUpdate-nptf {
  padding: 25px 0;
  color: #9a9c9c;
  font-size: 18px;
}
@media only screen and (max-width: 767px){
  .error-pageui-code p br{
      display: none
  }
  .error-pageui-code p{
      font-size: 14px;
  }
  .error-pageui-code img{
      max-width: 100%;
      font-size: 14px;
  }
}

.custom_static_btn {
  width: 60%;
  max-width:200px;
  font-size: 16px !important;
  background-color: #6dcff6 !important;
}
.error-pageui {
  padding-top: 65px;
}