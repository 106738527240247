@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,400i,500,500i,600&display=swap);
body {
  margin: 0;
  font-family: 'Fira Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 70px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mt-10{
  margin-top: 1.5rem;
}
 
.MuiDialogTitle-root {
  color: #49589B !important;
}
.manageBox .Theme-btn {
  background: #49589B;
  border-radius: 5px;
  min-width: 140px;
  height: 31px;
  padding:0;
  color:#fff;
}
.manageBox .Theme-btn:hover {
 background: #233067;
 box-shadow:none;
}
.managePopper {
  min-width: 140px;
  left: 0px !important;
}

 .css-1pahdxg-control:hover,
 .tagSelect div:hover {
    border-color: transparent !important;
    box-shadow:none;
  }
  .css-1pahdxg-control,
  .tagSelect div {
    box-shadow: none !important;
    border: 0 !important;
    outline: none !important;
  }

   .tagSelect .css-1wa3eu0-placeholder {
     color:#fff;
   }

   @media (max-width: 375px) {
   .MuiDialog-paperWidthMd {
     max-width: 100% !important;
   }
  }
   .text-center{
     text-align:center;
   }
   

.ui.segment.error-pageui-code{    
  background:transparent; 
  border-radius: 6px;
  box-shadow:none;
  border: 1px solid #FFFFFF;
}

.contentUpdate-nptf {
  padding: 25px 0;
  color: #9a9c9c;
  font-size: 18px;
}
@media only screen and (max-width: 767px){
  .error-pageui-code p br{
      display: none
  }
  .error-pageui-code p{
      font-size: 14px;
  }
  .error-pageui-code img{
      max-width: 100%;
      font-size: 14px;
  }
}

.custom_static_btn {
  width: 60%;
  max-width:200px;
  font-size: 16px !important;
  background-color: #6dcff6 !important;
}
.error-pageui {
  padding-top: 65px;
}
